import React from 'react'

const Header = () => {
    return (
        <div>
            <h2>
                <p>Expense Tracker</p>
                <p>By Ketan Shukla</p>
                <p>Last Updated Nov 11, 2020</p>
            </h2>
        </div>
    )
}

export default Header
//exporting default header.